import React from 'react'
import styles from './Scroller.module.scss'

const Scroller = () => {
  return (
    <section className={styles.scroller}>
      <p>| Frontend developer - React - TypeScript - JavaScript - HTML - CSS - Sass - Git - jQuery - Hire me! |</p>
      <p> Frontend developer - React - TypeScript - JavaScript - HTML - CSS - Sass - Git - jQuery - Hire me! </p>
    </section>
  )
}

export default Scroller