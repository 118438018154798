import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import styles from './Email.module.scss';

function Email(props) {
  const form = useRef();

  const [displayEmailSent, setDisplayEmailSent] = React.useState(false);
  const [submitButtonActive, setSubmitButtonActive] = React.useState(true);
  const [hoveringOnSubmit, setHoveringOnSubmit] = React.useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setSubmitButtonActive(false);
    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const message = document.getElementById('message').value;

    const contactParams = {
      from_name: name,
      reply_to: email,
      message: message,
    };

    emailjs
      .send(
        'service_d7qc25j',
        'template_fgozdve',
        contactParams,
        '_mhjyx5DvFJz3a1E9'
      )
      .then(
        (result) => {
          setDisplayEmailSent(true);
          setTimeout(function () {
            setDisplayEmailSent(false);
            setSubmitButtonActive(true);
          }, 5000);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const toggleHover = () =>
    hoveringOnSubmit ? setHoveringOnSubmit(false) : setHoveringOnSubmit(true);

  return (
    <div id="emailMe">
      <form ref={form} id={styles.emailForm} onSubmit={sendEmail}>
        {displayEmailSent && <h3>email is sent!</h3>}
        <input
          type="text"
          id="name"
          name="from_name"
          placeholder="Your name"
          required
          className={styles['form__input']}
          style={{ borderColor: props.textColor }}
        />
        <input
          type="email"
          id="email"
          name="reply_to"
          placeholder="Your email"
          required
          className={styles['form__input']}
          style={{ borderColor: props.textColor }}
        />
        <textarea
          id="message"
          name="message"
          placeholder="Your message"
          required
          className={styles['form__input']}
          style={{ borderColor: props.textColor }}
        />
        <button
          className={`${styles['form__submit']}`}
          style={{
            borderColor: props.textColor,
            backgroundColor: hoveringOnSubmit ? props.textColor : '',
            color: hoveringOnSubmit ? props.backgroundColor : '',
          }}
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
          type="submit"
          disabled={!submitButtonActive}
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default Email;
