import React, { useState } from 'react';
import styles from './Frontpage.module.scss';
import useScrollPosition from '../hooks/useScrollPosition';

const Frontpage = ({ workRef }) => {
  const [cursorX, setCursorX] = useState(0);
  const [cursorY, setCursorY] = useState(0);
  const scrollPosition = useScrollPosition();

  const saveCursorPosition = (x, y) => {
    setCursorX((x / window.innerWidth) * 100);
    setCursorY((y / window.innerWidth) * 100);
  };

  return (
    <div onMouseMove={(e) => saveCursorPosition(e.clientX, e.clientY)}>
      <main id={styles['frontpage__main-container']}>
        <h2
          id={styles['frontpage__title']}
          style={{ marginLeft: `${scrollPosition / 100}em` }}
        >
          FRONT-END DEVELOPER
        </h2>
        <div id={styles['frontpage__headshot-wrapper']}>
          <img
            id={styles['frontpage__headshot']}
            src="/Hich.jpg"
            alt="Hicham"
            style={{
              marginLeft: cursorX,
              marginTop: cursorY,
            }}
          />
        </div>
        <div
          id={styles['frontpage__undertitle-container']}
          style={{ gap: `${scrollPosition / 50}em` }}
        >
          <span
            onClick={() =>
              workRef.current.scrollIntoView({ behavior: 'smooth' })
            }
          >
            ↓
          </span>
          <h1>HICHAM WAKRIM</h1>
        </div>
      </main>
    </div>
  );
};

export default Frontpage;
