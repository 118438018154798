import React, { useEffect, useRef, useState } from 'react';
import styles from './App.module.scss';
import colors from './variables.module.scss';
import Header from './Components/Header';
import Frontpage from './Components/Frontpage';
import Scroller from './Components/Scroller';
import Work from './Components/Work';
import Skills from './Components/Skills';
import Contact from './Components/Contact';
import Footer from './Components/Footer';

function App() {
  const [mainColor, setMainColor] = useState('');

  const homeRef = useRef();
  const workRef = useRef();
  const aboutRef = useRef();
  const contactRef = useRef();

  useEffect(() => {
    const randomNumber = Math.floor(Math.random() * 5);
    const colorsArray = Object.entries(colors);
    const filteredColors = colorsArray.filter((color) => color[0] !== 'grey');
    const randomColor = filteredColors[randomNumber][1];

    setMainColor(randomColor);
  }, []);

  return (
    <div style={{ backgroundColor: mainColor }}>
      <div className={styles.container}>
        <Header
          homeRef={homeRef}
          workRef={workRef}
          aboutRef={aboutRef}
          contactRef={contactRef}
          textColor={mainColor}
        />
        <div ref={homeRef}>
          <Frontpage workRef={workRef} />
          <Scroller />
          <Work
            backgroundColor={colors.grey}
            textColor={mainColor}
            ref={workRef}
          />
          <Skills
            backgroundColor={mainColor}
            textColor={colors.grey}
            ref={aboutRef}
          />
          <Contact
            backgroundColor={colors.grey}
            textColor={mainColor}
            ref={contactRef}
          />
        </div>
        <Footer textColor={mainColor} />
      </div>
    </div>
  );
}

export default App;
