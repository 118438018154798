import React from 'react';
import styles from './Footer.module.scss';

const Footer = ({ textColor }) => {
  return (
    <footer id={styles['footer']} style={{ color: textColor }}>
      <h4>© Hicham Wakrim</h4>
      <div id={styles['footer__based-in']}>
        <h4>Based in Rotterdam</h4>
        <a
          href="https://www.youtube.com/watch?v=UZryrt9UyKE&t=54s"
          target="_blank"
          rel="noreferrer"
        >
          <img
            id={styles['rdam-flag']}
            src="/rdam-flag.png"
            alt="Flag of Rotterdam, Netherlands"
          />
        </a>
      </div>
      <div id={styles['footer__links']}>
        <a
          href="https://www.linkedin.com/in/hwakrim/"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa-brands fa-linkedin-in" />
        </a>
        <a href="https://github.com/hichamw" target="_blank" rel="noreferrer">
          <i className="fa-brands fa-github" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
