import React from 'react';
import './Pages.scss';
import styles from './Skills.module.scss';

const skills = [
  { id: 1, name: 'Javascript', image: '/skills/javascript.png' },
  { id: 2, name: 'HTML/CSS', image: '/skills/html-css.png' },
  { id: 3, name: 'React', image: '/skills/react.png' },
  { id: 4, name: 'Git', image: '/skills/git.png' },
  { id: 5, name: 'Typescript', image: '/skills/typescript.png' },
  { id: 6, name: 'Storybook', image: '/skills/storybook.png' },
  { id: 7, name: 'SASS', image: '/skills/sass.png' },
  { id: 8, name: 'Next.js', image: '/skills/nextjs.png' },
  { id: 9, name: 'SQL', image: '/skills/sql.png' },
  { id: 10, name: 'Elm', image: '/skills/elm.png' },
];

const Skills = React.forwardRef((props, ref) => {
  return (
    <section ref={ref} style={{ backgroundColor: props.backgroundColor }}>
      <h2 style={{ color: props.textColor }}>Skills</h2>
      <h3 style={{ color: props.textColor }}>In order of proficiency</h3>
      <div className={styles['skills__container']}>
        {skills.map((skill) => (
          <div
            className={styles['skills__card']}
            key={skill.id}
            style={{ backgroundColor: props.textColor }}
          >
            <img
              className={styles['skills__card-image']}
              src={skill.image}
              alt={`logo of ${skill.name}`}
            />
            <h4 className={styles['skills__card-text']} style={{ color: props.backgroundColor }}>{skill.name}</h4>
          </div>
        ))}
      </div>
    </section>
  );
});

export default Skills;
