import React from 'react';
import Email from './Email.js';
import styles from './Contact.module.scss';

const Contact = React.forwardRef((props, ref) => {
  return (
    <section ref={ref} style={{ backgroundColor: props.backgroundColor }}>
      <h2
        className={styles['contact__title']}
        style={{ color: props.textColor }}
      >
        Wanna know more about how i can help? Hit me up!
      </h2>
      <div className={styles['contact__container']}>
        <Email darkModeOn={true} textColor={props.textColor} backgroundColor={props.backgroundColor} />
        <div className={styles['contact__alternative']}>
          <h4 style={{ color: props.textColor }}>Or send me a message on:</h4>
          <a href="https://www.linkedin.com/in/hwakrim/" target='_blank' rel="noreferrer" style={{ color: "blue", fontSize: "2rem" }}>LinkedIn</a>
        </div>
      </div>
    </section>
  );
});

export default Contact;
