import React from 'react';
import styles from './Header.module.scss';

const Header = ({ homeRef, workRef, aboutRef, contactRef, textColor }) => {
  const handleHomeClick = () => {
    homeRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleWorkClick = () => {
    workRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleAboutClick = () => {
    aboutRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleContactClick = () => {
    contactRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <header id={styles.header} style={{ color: textColor }}>
      <h3 id={styles['header__home-link']} onClick={handleHomeClick}>
        Hicham Wakrim
      </h3>
      <h3 className={styles['header__current-employer']}>
        Currently front-end developer @{' '}
        <a href="https://teamrockstars.nl" target="_blank" rel="noreferrer">
          Team Rockstars
        </a>
      </h3>

      <nav>
        <span className={styles['header__nav-link']} onClick={handleWorkClick}>
          Work
        </span>
        <span className={styles['header__nav-link']} onClick={handleAboutClick}>
          Skills
        </span>
        <span
          className={styles['header__nav-link']}
          onClick={handleContactClick}
        >
          Contact
        </span>
      </nav>
    </header>
  );
};

export default Header;
