import React from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import styles from './Work.module.scss';

const past_companies = [
  {
    name: 'Team Rockstars IT',
    role: 'Front-end developer',
    period: 'May 2023 - now',
    image: '/past_companies/RS-icon.png',
    website: 'https://teamrockstars.nl/',
  },
  {
    name: 'Stager',
    role: 'Junior software engineer',
    period: 'September 2021 - April 2023',
    image: '/past_companies/stager-icon.png',
    website: 'https://stager.co/',
  },
  {
    name: 'Stager',
    role: 'Graduate intern',
    period: 'February 2021 - July 2021',
    image: '/past_companies/stager-icon.png',
    website: 'https://stager.co/',
  },
  {
    name: 'Insezo',
    role: 'Full-stack developer',
    period: 'April 2019 - Januari 2020',
    image: '/past_companies/insezo-icon.png',
    website: 'https://insezo.com/',
  },
  {
    name: 'Keylane',
    role: 'Software-engineering support',
    period: 'March 2018 - September 2018',
    image: '/past_companies/keylane-icon.png',
    website: 'https://keylane.com/',
  },
  {
    name: 'Notive',
    role: 'Full-stack intern',
    period: 'September 2017 - January 2018',
    image: '/past_companies/notive-icon.png',
    website: 'https://notive.nl/',
  },
];

const Work = React.forwardRef((props, ref) => {
  return (
    <section ref={ref} style={{ backgroundColor: props.backgroundColor }}>
      <h2 style={{ color: props.textColor }}>Work experience</h2>
      <div className={styles['timeline-container']}>
        <div>
          {props.textColor !== '' && (
            <VerticalTimeline lineColor={props.textColor}>
              {past_companies.map((company) => (
                <VerticalTimelineElement
                  contentStyle={{
                    background: props.textColor,
                    color: props.backgroundColor,
                  }}
                  contentArrowStyle={{ borderRightColor: props.textColor }}
                  iconStyle={{ boxShadow: 'none' }}
                  icon={
                    <img src={company.image} alt={`Logo of ${company.name}`} />
                  }
                >
                  <h3
                    className="vertical-timeline-element-title"
                    style={{ marginBottom: '0.5em' }}
                  >
                    <a href={company.website} target="_blank" rel="noreferrer">
                      {company.name}
                    </a>
                  </h3>
                  <h4 className="vertical-timeline-element-subtitle">
                    {company.role}
                  </h4>
                  <p>{company.period}</p>
                </VerticalTimelineElement>
              ))}
            </VerticalTimeline>
          )}
        </div>
      </div>
    </section>
  );
});

export default Work;
